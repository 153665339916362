<template>
    <div class="modal-request-app-review">
        <div class="head m-b-16">
            <h3 class="header f-bold">리뷰를 남겨주세요 🙏</h3>
            <i class="material-icons" @click="close">close</i>
        </div>
        <div class="m-b-28" v-if="!hasFeedback">리뷰는 큰 힘이 됩니다!</div>
        <div class="body m-b-20" v-if="hasFeedback">
            <div class="copy-review m-b-8">
                <p>앞서 남긴 피드백 내용을 <strong>복사</strong>해서<br />편하게 리뷰를 남겨보세요!</p>
                <button class="copy" @click="copy">복사하기</button>
            </div>
            <textarea class="review-text" v-html="feedback" />
        </div>
        <button class="btn btn-primary" v-html="buttonText" @click="clickCTA" />
    </div>
</template>
<script>
import npsService from '@/services/nps'

export default {
    name: 'ModalRequestAppReview',
    props: ['options'],
    data: () => ({
        hasFeedback: false,
        feedback: '',
    }),
    computed: {
        buttonText() {
            const store = this.$isIOS() ? '앱스토어' : '플레이스토어'

            return `${store}에 리뷰 남기기`
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.feedback = this.options.feedback || ''
            this.hasFeedback = this.feedback.length > 0
        },
        copy() {
            const feedbackElm = document.querySelector('.review-text')
            const range = document.createRange()
            feedbackElm.select()
            range.selectNode(feedbackElm)
            window.getSelection().addRange(range)
            document.execCommand('copy')
            this.$toast.success('클립보드에 복사되었어요!')
        },
        async clickCTA() {
            try {
                const nps = this.options.nps
                await npsService.updateNPS(nps.id, { cta_review: 1 })
            } catch (e) {
                console.error(e)
            } finally {
                setTimeout(() => this.openAppStore(), 500)
                setTimeout(() => this.$emit('close'), 1000)
            }
        },
        openAppStore() {
            window.open('https://abr.ge/h5p8s')
        },
        async close() {
            try {
                const nps = this.options.nps
                await npsService.updateNPS(nps.id, { cta_review: 0 })
            } catch (e) {
                console.error(e)
            } finally {
                this.$emit('close')
            }
        },
    },
}
</script>
